import React from 'react';
import DOM from 'react-dom';

class Portal extends React.PureComponent {

  state = {
    root: null,
  };

  componentDidMount() {
    this.root = document.createElement('div');
    const el = document.getElementById('___gatsby');
    el.appendChild(this.root);

    this.setState({
      root: this.root,
    });
  }

  render() {
    if (!this.state.root) return null;
    return DOM.createPortal(this.props.children, this.state.root);
  }

}

export default Portal;
