import React from 'react';
import cx from 'classnames';
import algolia from 'algoliasearch';

import MaterialIcon from 'material-icons-react';

import styles from './styles.module.scss';

class Search extends React.PureComponent {
  timeout = null;

  client = algolia('JZ3H1ZOEMU', 'a9e238b0d0838b58e0591f83f066012b');

  index = null;

  state = {
    open: false,
    query: '',
    results: [],
  };

  componentDidMount() {
    this.index = this.client.initIndex('rnfb');
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  _toggle = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }));
  };

  _onChange = e => {
    const query = e.target.value;

    this.setState({ query });
    clearTimeout(this.timeout);

    if (query.length > 3) {
      this.timeout = setTimeout(() => {
        this.index.search(
          {
            query,
          },
          (error, { hits }) => {
            this.setState({
              results: hits,
            });
            console.log(error, hits);
          }
        );
      }, 100);
    }
  };

  render() {
    return (
      <div className={cx(styles.search, { [styles.open]: this.state.open })}>
        <MaterialIcon icon="search" color="#fff" size={29} />
        <input
          type="text"
          placeholder="Search..."
          onFocus={this._toggle}
          onBlur={this._toggle}
          value={this.state.value}
          onChange={this._onChange}
        />
        {!!this.state.results.length && (
          <div className={styles.results}>
            {JSON.stringify(this.state.results)}
          </div>
        )}
      </div>
    );
  }
}

export default Search;
