import styled, { css } from 'styled-components';
import UnstyledLink from '../Link';

export const Heading = styled.h1`
  display: grid;
  grid-template-columns: 1fr auto;
`;

export const Link = styled(UnstyledLink)`
  ${props => props.method && css`
    position: relative;

    &:before {
      content: '#';
      padding-right: 10px;

      ${props => props.theme.desktop`
        left: -25px;
        position: absolute;
        font-size: 2.6rem;
        top: -4px;
      `}
    }
  `}
`;

export const Actions = styled.span`
  display: flex;
  align-items: center;

  > * {
    margin-left: 10px;
  }
`;

