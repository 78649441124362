import React from 'react';
import { Sticky } from 'react-sticky';

import { withMaxWindowHeight } from '../../../../utils';
import Mask from '../../../../components/Mask';
import Portal from '../../../../components/Portal';
import Scrollbar from '../Scrollbar';
import { Container, Background, Menu } from './styled';

import Section from './Section';

function Inner() {
  return (
    <Menu>
      <Section title={'Quick Start'}>

      </Section>
      <Section title={'Core Reference'}>

      </Section>
    </Menu>
  );
}

class Sidebar extends React.Component {

  state = {
    open: false,
  };

  _toggle = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }));
  };

  render() {
    return (
      <>
        <Mask open={this.state.open} onClose={this._toggle} />
        <Portal>
          <Container open={this.state.open}>
            <Background>
              <Scrollbar>
                <Inner />
              </Scrollbar>
            </Background>
          </Container>
        </Portal>
        {this.props.children(this._toggle)}
      </>
    )
  }
}

Sidebar.Sticky = withMaxWindowHeight(({ maxHeight }) => {
  return (
    <Background>
      <Sticky>
        {({ style, isSticky }) => (
          <Scrollbar
            style={{
              maxHeight: maxHeight - (isSticky ? 125 : 180),
              ...style,
              top: isSticky ? 122 : 0,
            }}
          >
            <Inner />
          </Scrollbar>
        )}
      </Sticky>
    </Background>
  );
}, 380);

export default Sidebar;
