import React from 'react';
import Link from '../../../components/Link';

import Github from 'react-feather/dist/icons/github';
import Twitter from 'react-feather/dist/icons/twitter';
import Layers from 'react-feather/dist/icons/layers';
import Hamburger from 'react-feather/dist/icons/menu';

import Search from '../Search';

import Nav from '../Nav';
import { Header, Project, Menu } from './styled';

export default class extends React.Component {
  render() {
    const { config } = this.props;

    return (
      <Header>
        <div>
          <Project>
            <Link to={`/oss/${config.npm}`}>
              <img src={config.logo} alt={config.name} />{' '}
              <span className="project">{config.name}</span>
            </Link>
          </Project>
          <Menu>
            <Menu.Desktop>
              <li>
                <Link to={'/'}>
                  <img
                    className={'hvr-bounce-in'}
                    src="https://static.invertase.io/assets/invertase-logo-small.png"
                    alt="Invertase"
                  />
                </Link>
              </li>
              <li>
                <Link to={`https://github.com/${config.repo}`}>
                  <Github />
                </Link>
              </li>
              {!!config.twitter && (
                <li>
                  <Link to={`https://twitter.com/@${config.twitter}`}>
                    <Twitter />
                  </Link>
                </li>
              )}
              <li>
                <Link to={`/oss/${config.npm}/docs`}>
                  <Layers /> <span>Docs</span>
                </Link>
              </li>
              <li>
                <Search />
              </li>
            </Menu.Desktop>

            <Menu.Mobile>
              <Nav config={config}>
                {(toggle) => (
                  <li onClick={toggle}>
                    <Hamburger />
                  </li>
                )}
              </Nav>
              <li>
                <Search />
              </li>
            </Menu.Mobile>
          </Menu>
        </div>
      </Header>
    );
  }
}
