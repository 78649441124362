import React from 'react';
import { renderToString } from 'react-dom/server';
import Prism from 'prismjs';

function Method({ children = [], language = 'javascript' }) {
  const content = children.map((child, index) => {
    if (React.isValidElement(child)) {
      return (
        <span key={`element_${index}`} className="token keyword">
          {child}
        </span>
      );
    }

    return (
      <span
        key={`inline_${index}`}
        dangerouslySetInnerHTML={{
          __html: Prism.highlight(child, Prism.languages[language], language),
        }}
      />
    );
  });

  return (
    <pre>
      <code>{content}</code>
    </pre>
  );
}

export default Method;
