import styled, { css } from 'styled-components';
import Scrollspy from 'react-scrollspy';

export const List = styled(Scrollspy)`
  margin: 0;
  padding: 0 10px 0;
  list-style: none;
  font-size: 1.6rem;
  
  li {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:global(.active) {
      color: #e96900;
    }
    
    &:global(.foobar) {
      background: red;
    }

    &.heading {
      margin-top: 20px;
      font-weight: 600;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      padding: 10px 0;
    }

    &.method {
      padding-left: 15px;
      margin: 10px 0;
    }
  }
`;

List.Item = styled.li`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  ${props => props.heading && css`
    margin-top: 20px;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px 0;
  `}
  
  ${props => props.method && css`
    padding-left: 15px;
    margin: 10px 0;
  `}
`;
