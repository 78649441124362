import React from 'react';
import styled from 'styled-components';

export default styled.div`
  overflow-y: auto;
  
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.rgba(props.theme.primary, 0.3)};
  }
    ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.primary};
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;
