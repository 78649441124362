import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  a:hover {
    color: ${props => props.theme.primary};
  }
`;

export const Project = styled.div`
  font-family: 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
`;

export const Container = styled.div`
  padding-top: 64px;
`;

export const Children = styled.div`
  min-height: calc(100vh - 64px - 200px);
`;
