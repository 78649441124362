import { css } from 'styled-components';
import color from 'color';
import hexRgb from 'hex-rgb';

export const mobile = (...args) => css`
  @media (max-width: 881px) {
    ${css(...args)}
  }
`;

export const tablet = (...args) => css`
  @media (min-width: 881px) and (max-width: 1023px) {
    ${css(...args)}
  }
`;

export const desktop = (...args) => css`
  @media (min-width: 1024px) {
    ${css(...args)}
  }
`;

export const media = (px = 0, type = 'max') => (...args) => css`
  @media (${type}-width: ${px}px) {
    ${css(...args)}
  }
`;

export const darken = (str = '#fff', decimal = 1.0) => {
  return color(str).darken(decimal).hsl().string();
};

export const lighten = (str = '#000', decimal = 1.0) => {
  return color(str).lighten(decimal).hsl().string();
};

export const rgba = (str, alpha = 1) => {
  const [r, g, b] = hexRgb(str, { format: 'array' });
  return `rgba(${r},${g}, ${b}, ${alpha})`;
};
