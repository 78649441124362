import React from 'react';
import { push } from 'gatsby';
import styled from 'styled-components';

import { QueryConsumer } from '../../context';

const Select = styled.select`
  width: 100%;
  height: 30px;
`;

function Versions() {
  return (
    <QueryConsumer>
      {({ project, version }) => (
        <Select
          value={version}
          onChange={e => push(`/todo/${e.target.value}`)}
        >
          {project.config.versions.map(version => (
            <option key={version} value={version}>
              {version}
            </option>
          ))}
        </Select>
      )}
    </QueryConsumer>
  );
}

export default Versions;
