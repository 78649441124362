import React from 'react';
import PropTypes from 'prop-types';
import { StickyContainer } from 'react-sticky';

import 'normalize.css/normalize.css';
import './hover.css';
import './styles.css';

const Page = ({ children }) => {
  return (
    <>
      <StickyContainer>{children}</StickyContainer>
    </>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
