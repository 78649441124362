import React from 'react';
import MaterialIcon from 'material-icons-react';
import { Sticky } from 'react-sticky';

import { FAB, Content } from './styled';

export default class extends React.PureComponent {
  state = {
    open: false,
  };

  _toggle = () => {
    this.setState(
      prevState => ({
        open: !prevState.open,
      }),
      () => {
        document.body.style.overflow = this.state.open ? 'hidden' : 'auto';
      }
    );
  };

  render() {
    return (
      <>
        <Sticky>
          {({ style, isSticky }) => (
            <Content
              open={this.state.open}
              style={{
                ...style,
                top: isSticky ? 122 : 180,
              }}
            >
              <div>{this.props.children}</div>
            </Content>
          )}
        </Sticky>
        <FAB role="button" onClick={this._toggle}>
          <MaterialIcon
            icon={this.state.open ? 'unfold_less' : 'unfold_more'}
            color="#ffffff"
            size={28}
          />
        </FAB>
      </>
    );
  }
}
