import React from 'react';

import Link from './Link';

function Anchor({ href, ...other }) {
  return (
    <Link
      {...other}
      to={href}
    />
  );
}

export default Anchor;
