import React from 'react';
import { Sticky } from 'react-sticky';
import Hamburger from 'react-feather/dist/icons/menu';

import Sidebar from '../Sidebar';
import { Tabs, Row, Menu, Links, Link, Actions } from './styled';

import { QueryConsumer } from '../../context';
import Container from '../../../../components/Container';

export default function ({ sidebar = false, actions = [] }) {
  return (
    <QueryConsumer>
      {({ project, group }) => {
        return (
          <Tabs>
            <Row>
              <Container>
                <h3>Documentation</h3>
              </Container>
            </Row>
            <Sticky>
              {({ style, isSticky }) => (
                <Row
                  style={{
                    ...style,
                    top: isSticky ? 64 : 0,
                  }}
                >
                  <Container>
                    <Links>
                      {sidebar && (
                        <Sidebar>
                          {(toggle) => (
                            <Menu onClick={toggle}>
                              <Hamburger />
                            </Menu>
                          )}
                        </Sidebar>
                      )}
                      <Links.Scrollable sidebar={sidebar}>
                        <Link
                          active={!group}
                          to={`/`}
                        >
                          Overview
                        </Link>

                        <Link
                          active={group === 'guides'}
                          to={`/guides`}
                        >
                          Guides
                        </Link>

                        <Link
                          active={group === 'installation'}
                          to={`/${project.config.latest_version}/installation`}
                        >
                          Installation
                        </Link>

                        <Link
                          active={group === 'reference'}
                          to={`/${project.config.latest_version}/reference`}
                        >
                          Reference
                        </Link>

                        {!!actions.length && (
                          <Actions>{actions}</Actions>
                        )}
                      </Links.Scrollable>
                    </Links>
                  </Container>
                </Row>
              )}
            </Sticky>
          </Tabs>
        );
      }}
    </QueryConsumer>
  );
}
