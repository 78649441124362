import React from 'react';
import axios from 'axios';
import dayjs from 'dayjs';

import Tag from '../../components/Tag';
import Link from '../../rehype/Link';

import { Item, Status, Comments, Labels } from './styled';

const ENDPOINT = (limit = 5) =>
  `https://api.github.com/repos/invertase/react-native-firebase/issues?per_page=${limit}`;

class Github extends React.PureComponent {
  state = {
    loading: true,
    error: false,
    issues: [],
  };

  componentDidMount() {
    this._fetch();
  }

  _fetch = async () => {
    try {
      const { data } = await axios.get(ENDPOINT(this.props.limit));

      if (data.quota_remaining === 0) {
        return this.setState({
          loading: false,
          error: true,
        });
      }

      this.setState({
        loading: false,
        issues: data,
      });
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
        error: true,
      });
    }
  };

  render() {
    return (
      <div>
        <h2>Github Issues</h2>
        {this.state.issues.map(issue => (
          <Item
            key={issue.html_url}
          >
            <Status status={issue.state}>
              {issue.state === 'open' && !issue.pull_request && (
                <svg viewBox="0 0 14 16" version="1.1" width="14" height="16">
                  <path
                    fillRule="evenodd"
                    d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
                  />
                </svg>
              )}
              {issue.state === 'open' && issue.pull_request && (
                <svg viewBox="0 0 12 16" version="1.1" width="12" height="16">
                  <path
                    fillRule="evenodd"
                    d="M11 11.28V5c-.03-.78-.34-1.47-.94-2.06C9.46 2.35 8.78 2.03 8 2H7V0L4 3l3 3V4h1c.27.02.48.11.69.31.21.2.3.42.31.69v6.28A1.993 1.993 0 0 0 10 15a1.993 1.993 0 0 0 1-3.72zm-1 2.92c-.66 0-1.2-.55-1.2-1.2 0-.65.55-1.2 1.2-1.2.65 0 1.2.55 1.2 1.2 0 .65-.55 1.2-1.2 1.2zM4 3c0-1.11-.89-2-2-2a1.993 1.993 0 0 0-1 3.72v6.56A1.993 1.993 0 0 0 2 15a1.993 1.993 0 0 0 1-3.72V4.72c.59-.34 1-.98 1-1.72zm-.8 10c0 .66-.55 1.2-1.2 1.2-.65 0-1.2-.55-1.2-1.2 0-.65.55-1.2 1.2-1.2.65 0 1.2.55 1.2 1.2zM2 4.2C1.34 4.2.8 3.65.8 3c0-.65.55-1.2 1.2-1.2.65 0 1.2.55 1.2 1.2 0 .65-.55 1.2-1.2 1.2z"
                  />
                </svg>
              )}
            </Status>
            <div>
              <h4>
                <Link to={issue.html_url} target="_blank">
                  {issue.title}
                </Link>
              </h4>
              <Link to={issue.user.html_url}>
                {`#${issue.number} opened on ${dayjs(issue.created_at).format(
                  'D MMM'
                )} by ${issue.user.login}`}
              </Link>
            </div>
            <Comments>
              {!!issue.comments && (
                <>
                  <svg viewBox="0 0 16 16" width="16" height="16">
                    <path
                      d="M14 1H2c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1h2v3.5L7.5 11H14c.55 0 1-.45 1-1V2c0-.55-.45-1-1-1zm0 9H7l-2 2v-2H2V2h12v8z" />
                  </svg>
                  <span>{issue.comments}</span>
                </>
              )}
            </Comments>
            {!!issue.labels.length && (
              <Labels>
                {issue.labels.map(label => (
                  <Tag key={label.name} color={`#${label.color}`}>
                    {label.name}
                  </Tag>
                ))}
              </Labels>
            )}
          </Item>
        ))}
      </div>
    );
  }
}

export default Github;
