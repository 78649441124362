import React from 'react';

import Link from './Link';
import { QueryConsumer } from '../context';

function Reference({ to = '', children }) {
  const [module, file] = to.split('.');

  return (
    <QueryConsumer>
      {({ version }) => {
        const url = `/docs/${version}/reference/${
          file ? file.toLowerCase() : module
        }`;
        return <Link to={url}>{children || file || module}</Link>;
      }}
    </QueryConsumer>
  );
}

export default Reference;
