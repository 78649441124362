import styled, { css } from 'styled-components';

export const Item = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr 20px;
  grid-gap: 10px;
  padding: 20px 0;
  
  :not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  h4 {
    font-size: 1.6rem;
    margin: 0 0 10px;
  }
`;

export const Status = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3px;
  
  svg path {
    ${props => props.status === 'open' && css`
      fill: #28a745;
    `}
    ${props => props.status === 'open' && css`
      fill: #cb2431;
    `}
  }
`;

export const Comments = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3px;

  svg {
    position: relative;
    top: 4px;
    left: -4px;
  }
`;

export const Labels = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  margin-top: 10px;
  padding: 0 40px;

  > span {
    margin-right: 5px;
  }
`;
