import React from 'react';
import stc from 'string-to-color';

function Badge({ key = 'type', children = [] }) {
  const str = children.join(' ').toLowerCase();
  const color = stc(str.toLowerCase()).replace('#', '');

  return (
    <img src={`https://img.shields.io/badge/${key}-${children.join('')}-${color}.svg`} alt={children.join('')} />
  );
}

export default Badge;
