import React from 'react';

import Link from '../../../../components/Link';

import Tag from '../../components/Tag';

import styles from './styles.module.scss';
import { QueryConsumer } from '../../context';
import Pagination from '../../components/Pagination';

function PageList({ data, pagination }) {
  return (
    <QueryConsumer>
      {({ query }) => {
        // Generate array of tags
        const tags = (query.tags || '').split(',').filter(Boolean);

        /**
         * Children renderer
         * @param faqs
         * @returns {*}
         */
        const children = faqs => (
          <div className={styles.list}>
            {faqs.map(({ fields, excerpt }, index) => (
              <div key={index} className={styles.item}>
                <h3 className={styles.title}>
                  <Link to={fields.slug}>{fields.shortTitle}</Link>
                  {!!fields.frontmatter.tags && (
                    <span className={styles.tags}>
                      {fields.frontmatter.tags.map(f => (
                        <Tag handleQuery>{f}</Tag>
                      ))}
                    </span>
                  )}
                </h3>
                <p>{excerpt}</p>
              </div>
            ))}
          </div>
        );

        // Render Pagination
        if (pagination !== false) {
          return (
            <Pagination
              data={data.filter($ => {
                if (!tags.length) return true;
                if (tags.length && !$.fields.tags) return false;
                if (tags.length) {
                  for (let i = 0, len = tags.length; i < len; i++) {
                    if ($.fields.tags.includes(tags[i])) {
                      return true;
                    }
                  }
                }
                return false;
              })}
            >
              {children}
            </Pagination>
          );
        }

        // Render all children
        return children(data);
      }}
    </QueryConsumer>
  );
}

export default PageList;
