import React from 'react';
import styled from 'styled-components';

import Container from '../../../../components/Container';

import Markdown from '../../components/Markdown';
import Tabs from '../../components/Tabs';
import Breadcrumbs from '../../components/Breadcrumbs';

const Content = styled.div`
  margin: 7rem 0 3.5rem 0;
`;

function Default() {
  return (
    <>
      <Tabs />
      <Content>
        <Container>
          <Breadcrumbs />
          <Markdown />
        </Container>
      </Content>
    </>
  );
}

export default Default;
