import React from 'react';

import { QueryConsumer } from '../../context';

function Showcase() {
  return (
    <QueryConsumer>
      {({ content }) => (
        <div>
          {content.frontmatter.showcase.map(s => (
            <div>{s.title}</div>
          ))}
        </div>
      )}
    </QueryConsumer>
  );
}

export default Showcase;
