import React from 'react';
import url from 'url';
import { graphql } from 'gatsby';

import rehype from './rehype';
import Layout from './Layout';

import { QueryProvider } from './context';

class Docs extends React.PureComponent {
  /**
   * Clean up the graphql output to store in context
   * @returns {{project: *, document: *, related: string[]}}
   */
  get getContext() {
    const { location, data, pageContext } = this.props;
    const { related, document } = data;

    const context = {
      content: {
        html: rehype(document.htmlAst),
        excerpt: document.excerpt,
        title: document.fields.title,
        timeToRead: document.timeToRead,
        shortTitle: document.fields.shortTitle,
        repositoryPath: document.fields.repositoryPath,
        frontmatter: JSON.parse(document.fields.frontmatter),
        breadcrumbs: JSON.parse(document.fields.breadcrumbs),
        tableOfContents: document.headings.filter(
          $ => $.depth === 2 || $.depth === 3 || $.depth === 4,
        ).map($ => ({
          ...$,
          id: $.value.replace(/ /g, '-'),
        })),
      },
      group: pageContext.group,
      version: pageContext.version,
      layout: document.fields.layout,
      project: data.projects.edges[0].node,
      variables: JSON.parse(document.fields.variables),
      query: url.parse(location.href || '', true).query,
      related: related ? related.edges.map($ => $.node) : [],
    };

    console.log('Providing context: ', context);

    return context;
  }

  render() {
    return (
      <QueryProvider value={this.getContext}>
        <Layout />
      </QueryProvider>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query DocumentBySlug(
    $slug: String!
    $group: String!
    $version: String!
    $project: String!
  ) {
    document: markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      timeToRead
      headings {
        depth
        value
      }
      excerpt
      fields {
        slug
        title
        layout
        variables
        shortTitle
        frontmatter
        breadcrumbs
        repositoryPath
      }
    }
    projects: allOpenSourceProjectConfig(filter: { key: { eq: $project } }) {
      edges {
        node {
          key
          npm
          repo
          name
          logo
          base
          twitter
          description
          config {
            versions
            latest_version
          }
          theme {
            primary
            secondary
          }
        }
      }
    }
    related: allMarkdownRemark(
      filter: {
        fields: {
          slug: { ne: $slug }
          group: { eq: $group }
          version: { eq: $version }
        }
      }
      limit: 5
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            group
            shortTitle
          }
        }
      }
    }
  }
`;

export default Docs;
