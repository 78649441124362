import styled, { css } from 'styled-components';

const width = 265;

export const Menu = styled.nav`
  transform: translateX(${width + 10}px);
  transition: transform 0.3s ease-in;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 12;
  width: ${width}px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.4);
  background: #ffffff;
  pointer-events: none;
  
  ${props => props.open && css`
    pointer-events: auto;
    transform: translateX(0);
  `}
  
  ul {
    margin: 0;
    padding: 0;

    li {
      a {
        font-size: 1.6rem;
        display: flex;
        height: 56px;
        align-items: center;
        padding: 0 15px;

        svg, i, img {
          margin-right: 10px;
        }

        img {
          width: 25px;
        }
      }

      :not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
  }
`;
