import React from 'react';
import RehypeReact from 'rehype-react';

import withGuides from '../query/guides';
import withFAQs from '../query/faqs';
import withReleases from '../query/releases';

import PageList from '../components/PageList';

import Heading from './Heading';
import Button from './Button';
import Anchor from './Anchor';
import Table from './Table';
import Reference from './Reference';
import Method from './Method';
import Grid from './Grid';
import Card from './Card';
import StackOverflow from './StackOverflow';
import Github from './Github';
import Discord from './Discord';
import Pill from './Pill';
import Icon from './Icon';
import Image from './Image';
import Badge from './Badge';

/**
 * Convert any string based rehype props to types
 * @param props
 * @param ComposedComponent
 * @returns {*}
 */
function parseProps(props = {}, ComposedComponent) {
  const out = {};
  Object.entries(props).forEach(([key, value]) => {
    if (key === 'children') out[key] = value;
    else if (value === 'false') out[key] = false;
    else if (!isNaN(parseInt(value)) && typeof parseInt(value) === 'number')
      out[key] = parseInt(value);
    else out[key] = value;
  });

  return <ComposedComponent {...out} />;
}

const TYPES = {
  // html
  // div: props => React.createElement(React.Fragment, props),
  h1: props => <Heading {...props} el={'h1'} />,
  h2: props => <Heading {...props} el={'h2'} />,
  h3: props => <Heading {...props} el={'h3'} />,
  h4: props => <Heading {...props} el={'h4'} />,
  h5: props => <Heading {...props} el={'h5'} />,
  h6: props => <Heading {...props} el={'h6'} />,
  table: Table,
  button: Button,
  img: Image,
  a: Anchor,

  // custom
  method: Method,
  ref: Reference,
  grid: Grid,
  card: Card,
  guides: withGuides(PageList),
  faqs: withFAQs(PageList),
  releases: withReleases(PageList),
  'stack-overflow': StackOverflow,
  github: Github,
  discord: Discord,
  pill: Pill,
  icon: Icon,
  badge: Badge,
};

const components = {};

Object.entries(TYPES).forEach(
  ([key, value]) => (components[key] = props => parseProps(props, value))
);

const rehype = new RehypeReact({
  createElement: React.createElement,
  components,
}).Compiler;

export default rehype;
