import React from 'react';
import styled, { css } from 'styled-components';
import url from 'url';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import stc from 'string-to-color';
import contrast from 'font-color-contrast';

import { QueryConsumer } from '../../context';

const StyledSpan = styled.span`
  padding: 3px 5px;
  font-size: 1.4rem;
  border-radius: 3px;
  margin-right: 5px;
  background-color: ${props => props.color};
  color: ${props => contrast(props.color)}
  
  ${props => props.clickable && css`
    &:hover {
      cursor: pointer;
    }
  `}
`;

class Tag extends React.Component {

  _onClick = (query, tag) => {
    const tags = (query.tags || '').split(',').filter(Boolean);
    if (!this.props.handleQuery || tags.includes(tag)) return;

    navigate(
      url.format({
        query: {
          ...query,
          tags: [...tags, tag].join(','),
        },
      })
    );
  };

  render() {
    const { color, handleQuery, children } = this.props;

    return (
      <QueryConsumer>
        {({ query }) => (
          <StyledSpan
            clickable={!!handleQuery}
            onClick={() => this._onClick(query, children)}
            color={!!color ? color : stc(children)}
          >
            {children}
          </StyledSpan>
        )}
      </QueryConsumer>
    );
  }
}

Tag.propTypes = {
  color: PropTypes.string,
  children: PropTypes.string.isRequired,
  handleQuery: PropTypes.bool,
};

Tag.defaultProps = {
  handleQuery: false,
};

export default Tag;
