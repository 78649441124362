import React from 'react';

import Github from 'react-feather/dist/icons/github';
import Twitter from 'react-feather/dist/icons/twitter';
import Layers from 'react-feather/dist/icons/layers';

import Mask from '../../../components/Mask';
import Link from '../../../components/Link';
import Portal from '../../../components/Portal';

import { Menu } from './styled';

class Nav extends React.PureComponent {

  state = {
    open: false,
  };

  _toggle = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }));
  };

  render() {
    const { config } = this.props;

    return (
      <>
        <Mask open={this.state.open} onClose={this._toggle} />
        <Portal>
          <Menu open={this.state.open}>
            <ul>
              <li>
                <Link
                  to={`https://github.com/${config.repo}`}
                  onClick={this._toggle}
                >
                  <Github /> Github
                </Link>
              </li>
              {!!config.twitter && (
                <li>
                  <Link
                    to={`https://twitter.com/@${config.twitter}`}
                    onClick={this._toggle}
                  >
                    <Twitter /> {`@${config.twitter}`}
                  </Link>
                </li>
              )}
              <li>
                <Link to={`/oss/${config.npm}/docs`} onClick={this._toggle}>
                  <Layers /> Docs
                </Link>
              </li>
              <li>
                <Link to={'/'} onClick={this._toggle}>
                  <img
                    src="https://static.invertase.io/assets/invertase-logo-small.png"
                    alt="Invertase"
                  />
                  Invertase
                </Link>
              </li>
            </ul>
          </Menu>
        </Portal>
        {this.props.children(this._toggle)}
      </>
    );
  }
}

export default Nav;
