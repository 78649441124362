import React from 'react';
import ImageZoom from 'react-medium-image-zoom';

function Image({ src, alt = '' }) {
  return (
    <ImageZoom
      image={{
        src,
        alt,
      }}
    />
  );
}

export default Image;
