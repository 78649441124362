import React from 'react';
import styled from 'styled-components';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  grid-gap: ${props => props.gap}px;
  margin: ${props => props.gap}px 0;

  ${props => props.theme.mobile`
    display: block;
    margin: 0 !important;
  `}
`;

export default function ({ columns = '3', gap = '20', ...other }) {
  return (
    <Grid
      {...other}
      columns={columns}
      gap={gap}
    />
  );
};
