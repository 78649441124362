import styled, { css } from 'styled-components';

export const Row = styled.div`
  padding: 10px 5px;
`;

export const Question = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  align-items: center;
`;

export const Answers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 60px;
  width: 60px;
  
  ${props => props.answered && css`
    color: #4CAF50;
    border: 1px solid #4CAF50;
    border-radius: 3px
  `}
`;

Answers.Count = styled.div`
  font-size: 1.8rem;
`;

export const Meta = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 10px;

  > div {
    display: flex;
    align-items: center;
  }
`;

Meta.Owner = styled.div`
  flex-direction: row-reverse;

  img {
    width: 30px;
    height: 30px;
    border-radius: 3px;
    margin-left: 10px;
  }
`;
