import React from 'React';
import { Link as GatsbyLink } from 'gatsby';

const Link = ({ children, to, target = '_self', ...other }) => {
  const props = {
    target,
  };

  if (target === '_blank') {
    props.rel = 'noopener';
  }

  // Use Gatsby Link for internal links, and <a> for others
  if (Link.isInternal(to)) {
    return (
      <GatsbyLink to={to} {...other} {...props}>
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a href={to} {...other} {...props}>
      {children}
    </a>
  );
};

Link.isInternal = test => /^\/(?!\/)/.test(test);

export default Link;
