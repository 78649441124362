import styled from 'styled-components';

export const VersionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
`;

export const Reference = styled.div`
  display: grid;
  grid-template-columns: ${props => props.theme.sidebar}px 1fr ${props => props.theme.toc}px;
  
  ${props => props.theme.noSidebar`
    grid-template-columns: 1fr ${props => props.theme.toc}px;
 `};
  
  ${props => props.theme.contentOnly`
    grid-template-columns: 1fr;
  `}
`;

export const MarkdownWrapper = styled.div`
  margin: 0 auto;
  padding: 3rem 0;
  width: ${props => props.theme.content}px;
  
  ${props => props.theme.contentOnly`
    width: auto;
    margin: 0 10px;
    max-width: calc(100vw - 30px);
  `}
`;

export const SidebarWrapper = styled.div`
  ${props => props.theme.noSidebar`
    display: none;
  `}
`;

export const ToCWrapper = styled.div`
  ${props => props.theme.contentOnly`
    display: none;
  `}
`;
