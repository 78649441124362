import React from 'react';
import axios from 'axios';

import { Discord } from './styled';

const ENDPOINT =
  'https://discordapp.com/api/guilds/295953187817521152/widget.json';

export default class extends React.PureComponent {
  state = {
    loading: true,
    error: false,
    members: 0,
  };

  interval = null;

  componentDidMount() {
    this._fetch();
    this.interval = setInterval(this._fetch, 30000);
  }

  componentWillMount() {
    clearInterval(this.interval);
  }

  _fetch = async () => {
    try {
      const { data } = await axios.get(ENDPOINT);
      this.setState({
        members: data.members.length,
        loading: false,
      });
    } catch (e) {
      console.error(e);
      this.setState({
        error: true,
        loading: false,
      });
    }
  };

  render() {
    const { loading, error, members } = this.state;

    const container = status => {
      return children => (
        <Discord status={status}>
          {children}
        </Discord>
      );
    };

    if (loading) {
      return container('loading')(<span>loading...</span>);
    }

    if (error) {
      return container('error')(<span>connection error</span>);
    }

    return container('success')(<span>{members} online</span>);
  }
}
