import styled from 'styled-components';

export const Footer = styled.footer`
  background-color: #2d2d2d;
  min-height: 280px;
  padding: 50px;
  color: #ffffff;
  
  > div {
    margin: 0 auto;
    
    ${props => props.theme.desktop`
      width: 800px;
    `}
  }
`;

export const Grid = styled.div`
  display: block;
  
  ${props => props.theme.desktop`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  `}
  
  h4 {
    font-size: 1.6rem;
  }
  
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  
    li {
      a {
        display: block;
        padding: 2px 0;
      }
    }
  }
`;

export const Company = styled.div`
  padding-top: 40px;

  > div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    
    img {
      width: 30px;
      margin-right: 10px;
    }
  }
`;
