import React from 'react';

import GlobalLink from '../../../components/Link';
import { QueryConsumer } from '../context';

/**
 * Create a standard Link component from Gatsby
 * @param to
 * @param props
 * @return {*}
 * @constructor
 */
function Link({ to, ...props }) {
  return (
    <QueryConsumer>
      {({ project }) => (
        <GlobalLink
          {...props}
          to={GlobalLink.isInternal(to) ? `${project.base}${to}` : to}
        />
      )}
    </QueryConsumer>
  );
}

export default Link;
