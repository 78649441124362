import React from 'react';
import styled from 'styled-components';

import { QueryConsumer } from '../../context';
import Link from '../../rehype/Link';

const OrderedList = styled.ol`
  background-color: #f8f8f8;
  padding: 10px 20px;
  display: flex;
  list-style: none;
  margin: 0 0 40px;
  font-size: 1.6rem;
  overflow-x: auto;
  
  li a {
    display: block;
  }
`;

const Divider = styled.span`
  padding: 0 10px;
  color: initial !important;
`;

function Breadcrumbs() {
  return (
    <QueryConsumer>
      {({ content }) => {
        if (!content.breadcrumbs.length) {
          return null;
        }

        return (
          <OrderedList
            itemScope
            itemType="http://schema.org/BreadcrumbList"
          >
            {content.breadcrumbs.map(({ text, to }, index) => (
              <li
                key={to}
                itemProp="itemListElement"
                itemScope
                itemType="http://schema.org/ListItem"
              >
                <Link to={to} itemProp="item">
                  <span itemProp="name">{text}</span>
                  <meta itemProp="position" content={`${index + 1}`} />
                  {index + 1 !== content.breadcrumbs.length && (
                    <Divider>/</Divider>
                  )}
                </Link>
              </li>
            ))}
          </OrderedList>
        );
      }}
    </QueryConsumer>
  );
}

export default Breadcrumbs;
