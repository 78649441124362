import url from 'url';
import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import Paginate from 'react-js-pagination';
import { Helmet } from 'react-helmet';

import { QueryConsumer } from '../../context';
import styles from './styles.module.scss';

class Pagination extends React.Component {
  /** TODO is this working?
   * Generate Pagination links
   * @param currentPage
   * @param total
   * @param limit
   * @returns {*}
   */
  renderPaginationLinks(currentPage, total, limit) {
    return null;
    // const url = `${window.location.origin}${window.location.pathname}`;
    //
    // if (currentPage === 1) {
    //   return <link rel="next" href={`${url}?page=2`} />;
    // }
    //
    // if (currentPage > 1 && currentPage * limit < total) {
    //   return (
    //     <>
    //       <link rel="prev" href={`${url}?page=${currentPage - 1}`} />
    //       <link rel="next" href={`${url}?page=${currentPage + 1}`} />
    //     </>
    //   );
    // }
    //
    // return <link rel="prev" href={`${url}?page=${currentPage - 1}`} />;
  }

  /**
   *
   * @returns {*}
   */
  render() {
    const { range, data, children } = this.props;

    return (
      <QueryConsumer>
        {({ query }) => {
          const total = data.length;
          const limit = parseInt(query.limit || 15);
          const page = parseInt(query.page || 1);
          const pages = Math.ceil(data.length / limit);
          const startAt = (page - 1) * limit;
          const endAt = page - 1 + limit;

          return (
            <>
              {children(data.slice(startAt, endAt))}
              <br />
              <Paginate
                activePage={page}
                itemsCountPerPage={limit}
                totalItemsCount={total}
                pageRangeDisplayed={range}
                onChange={page => {
                  navigate(
                    url.format({
                      query: {
                        ...query,
                        page,
                      },
                    })
                  );
                }}
                innerClass={styles.pagination}
              />
            </>
          );
        }}
      </QueryConsumer>
    );
  }
}

Pagination.propTypes = {
  range: PropTypes.number,
  data: PropTypes.array.isRequired,
  children: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  range: 5,
};

export default Pagination;
