import React from 'react';
import styled, { css } from 'styled-components';

import Portal from './Portal';

const Mask = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  background-color: #000000;
  transition: opacity 0.3s ease-in;
  opacity: 0;
  pointer-events: none;
  
  ${props => props.open && css`
    pointer-events: auto;
    opacity: 0.7;
  `}
`;

export default class extends React.Component {

  componentDidUpdate() {
    if (this.props.open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  render() {
    return (
      <Portal>
        <Mask open={this.props.open} onClick={this.props.onClose} />
      </Portal>
    );
  }
}
