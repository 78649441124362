import React from 'react';

/**
 * Throttle an window event lister and execute when animation frames are available
 * @param type
 * @param listener Function
 * @return Function
 */
export function throttledWindowEvent(type, listener) {
  let running = false;

  const func = function() {
    if (running) return;
    running = true;
    requestAnimationFrame(() => {
      listener();
      running = false;
    });
  };

  window.addEventListener(type, func);
  return () => window.removeEventListener(type, listener);
}

/**
 * Calculate the height of the current window, minus a footer which may be in view
 * @param fromBottom
 * @returns {number}
 */
export function maxWindowHeight(fromBottom = 0) {
  if (typeof window === 'undefined') {
    return 0;
  }

  const body = document.body;
  const html = document.documentElement;
  const height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );

  const distanceFromBottom = height - window.innerHeight - window.scrollY;

  let subtractBottom = 0;

  if (distanceFromBottom <= fromBottom) {
    subtractBottom = fromBottom - distanceFromBottom;
  }

  return window.innerHeight - subtractBottom;
}

/**
 * Decorator for providing max height of a div
 * @param ComposedComponent
 * @param fromBottom
 */
export function withMaxWindowHeight(ComposedComponent, fromBottom) {
  return class extends React.Component {
    state = {
      scroll: 0,
      height: 0,
    };

    componentDidMount() {
      this.unsubResize = throttledWindowEvent('resize', this._handleResize);
      this.unsubScroll = throttledWindowEvent('scroll', this._handleScroll);
      this._handleScroll();
      this._handleResize();
    }

    componentWillUnmount() {
      this.unsubResize && this.unsubResize();
      this.unsubScroll && this.unsubScroll();
    }

    _handleResize = () => this.setState({ height: window.innerHeight });
    _handleScroll = () => this.setState({ scroll: window.scrollY });

    render() {
      return (
        <ComposedComponent
          {...this.props}
          maxHeight={maxWindowHeight(fromBottom)}
        />
      );
    }
  };
}
