import React from 'react';

import { QueryConsumer } from '../../context';

import { Heading, Actions, Link } from './styled';

export default function({ el, children = [] }) {
  const text = [];
  const actions = [];

  // Grab any React elements and put then into an actions array
  for (let i = 0, len = children.length; i < len; i++) {
    if (React.isValidElement(children[i])) actions.push(children[i]);
    else if (!!children[i]) text.push(children[i]);
  }

  // Create the id
  const id = `#${text.join(' ').replace(/ /g, '-')}`;

  let heading = <span>{text}</span>;

  const container = (
    <>
      <QueryConsumer>
        {({ layout }) => {
          if (layout === 'reference' && el !== 'h1') {
            return (
              <Link
                to={id}
                method={el === 'h3'}
              >
                {heading}
              </Link>
            );
          }

          return heading;
        }}
      </QueryConsumer>
      <Actions>{actions}</Actions>
    </>
  );

  return (
    <Heading
      id={id}
      as={el}
    >
      {container}
    </Heading>
  );
}
