import React from 'react';
import { ThemeProvider } from 'styled-components';
import Helmet from 'react-helmet';

import * as utils from '../utils';
import { GlobalStyle, Project, Children, Container } from './styled';

import Page from '../../components/Page';
import Header from './Header';
import Footer from './Footer';

export default function ({ config, children }) {
  return (
    <ThemeProvider
      theme={{
        ...config.theme,
        ...utils,
      }}
    >
      <Page>
        <GlobalStyle />
        <Helmet>
          <link rel="shortcut icon" type="image/png" href={config.favicon} />
        </Helmet>
        <Project>
          <Header config={config} />
          <Container>
            <Children>
              {children}
            </Children>
          </Container>
          <Footer config={config} />
        </Project>
      </Page>
    </ThemeProvider>
  );
}
