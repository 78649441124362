import React from 'react';
import { ThemeProvider } from 'styled-components';
import { StickyContainer } from 'react-sticky';
import Helmet from 'react-helmet';

import * as utils from './utils';
import { QueryConsumer } from './context';
import Project from '../../themes/project';

import Default from './layouts/Default';
import Reference from './layouts/Reference';
import Showcase from './layouts/Showcase';

function Layout() {
  return (
    <ThemeProvider
      theme={{
        ...utils,
      }}
    >
      <QueryConsumer>
        {({ layout, project, content }) => (
          <>
            <Helmet>
              <title>{content.title}</title>
              <meta name="theme-color" content="#2d2d2d" />
            </Helmet>
            <Project config={project}>
              <StickyContainer>
                {layout === 'default' && <Default />}
                {layout === 'reference' && <Reference />}
                {layout === 'showcase' && <Showcase />}
              </StickyContainer>
            </Project>
          </>
        )}
      </QueryConsumer>
    </ThemeProvider>
  );
}

export default Layout;
