import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  ${props => props.theme.contentOnly`
    // overflow-x: scroll;
    // width: calc(100vw - 20px);
    //
    // table td {
    //   min-width: 400px;
    // }
  `}
`;

export default function ({ children }) {
  return (
    <Container>
      <table>{children}</table>
    </Container>
  )
};
