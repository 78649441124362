import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { QueryConsumer } from '../context';

const withFAQs = ComposedComponent => {
  return class extends React.Component {
    /**
     * Return nodes as an array
     * @param allMarkdownRemark
     * @returns {*}
     */
    getList({ allMarkdownRemark }) {
      return allMarkdownRemark.edges.map($ => ({
        ...$.node,
        fields: {
          ...$.node.fields,
          frontmatter: JSON.parse($.node.fields.frontmatter),
        },
      }));
    }

    render() {
      return (
        <StaticQuery
          query={graphql`
            query {
              allMarkdownRemark(
                filter: {
                  fields: { group: { eq: "faqs" }, type: { eq: "child" } }
                }
              ) {
                edges {
                  node {
                    excerpt(pruneLength: 600)
                    fields {
                      slug
                      title
                      group
                      shortTitle
                      frontmatter
                    }
                  }
                }
              }
            }
          `}
          render={data => (
            <QueryConsumer>
              {() => (
                <ComposedComponent
                  {...this.props}
                  data={this.getList(data)}
                />
              )}
            </QueryConsumer>
          )}
        />
      );
    }
  };
};

export default withFAQs;
