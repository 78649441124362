import styled from 'styled-components';

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 44px;
  font-size: 1.7rem;
  font-weight: 300;
  background: #2d2d2d;
  color: #ffffff;
  padding: 10px;
  box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.2);
  z-index: 10;
  
  > div {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 20px;
  }
`;

export const Project = styled.div`
  a {
    display: flex;
    align-items: center;
  }
  
  img {
    margin-right: 10px;
    width: 40px;
  }
  
  ${props => props.theme.media(500)`
    span {
      display: none;
    }
  `}
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  
  ul {
    display: flex;
    flex-direction: row-reverse;
    margin: 0;
    padding: 0;
    list-style: none;
    
    ${props => props.theme.mobile`
      display: none;
    `}
    
    li {
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      a {
        display: flex;
        align-items: center;
        padding: 10px 20px;

        span {
          padding-left: 10px;
        }

        img {
          height: 24px;
        }
      }
    }
  }
`;

Menu.Desktop = styled.ul`
  &&& {
    ${props => props.theme.mobile`
      display: none;
    `}
  }
`;

Menu.Mobile = styled.ul`
  &&& {
    display: none;
  
    li {
      padding: 0 10px;
    }
    
    ${props => props.theme.mobile`
      display: inherit;
    `}
  }
`;
