import React from 'react';

import Link from '../../../components/Link';

import { Footer, Grid, Company } from './styled';

export default function ({ config }) {
  return (
    <Footer>
      <Grid>
        <div>
          <h4>Github</h4>
          <ul>
            <li>
              <Link to={`https://github.com/${config.repo}`}>Repository</Link>
            </li>
            <li>
              <Link to={`https://github.com/${config.repo}/blob/master/LICENSE`}>License</Link>
            </li>
            <li>
              <Link to={`https://github.com/${config.repo}/issues`}>Issues</Link>
            </li>
            <li>
              <Link to={`https://github.com/${config.repo}/pulls`}>Pull Requests</Link>
            </li>
            <li>
              <Link to={`https://github.com/${config.repo}/graphs/contributors`}>Contributors</Link>
            </li>
          </ul>
        </div>
        <div>
          <h4>Find us on</h4>
          <ul>
            {!!config.twitter && (
              <li>
                <Link to={`https://twitter.com/${config.twitter}`}>Twitter</Link>
              </li>
            )}
            <li>
              <Link to={`https://npmjs.com/package/${config.npm}`}>NPM</Link>
            </li>
            {!!config.discord && (
              <li>
                <Link to={config.discord}>Discord</Link>
              </li>
            )}
            <li>
              <Link to={`https://stackoverflow.com/questions/tagged/${config.npm}`}>Stack Overflow</Link>
            </li>
          </ul>
        </div>
        <div>
          <h4>Invertase</h4>
          <ul>
            <li>
              <Link to="/oss">Open Source Software</Link>
            </li>
            <li>
              <Link to="https://github.com/invertase">Github</Link>
            </li>
            <li>
              <Link to="https://twitter.com/invertaseio">Twitter</Link>
            </li>
          </ul>
        </div>
      </Grid>
      <Company>
        <div>
          <img src="https://static.invertase.io/assets/invertase-white.png" alt="Invertase.io" /> Invertase
          Limited <br />
        </div>
        <small>Copyright &copy; 2017-2018 Invertase Limited. All rights reserved.</small>
      </Company>
    </Footer>
  );
}
