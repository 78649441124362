import React from 'react';
import MaterialIcon from 'material-icons-react';

import Link from '../Link';
import { Container, Title } from './styled';

function Card({ title, to, icon, brand = 'default', children }) {
  const container = children => {
    // anchor
    if (to) {
      return (
        <Container anchor brand={brand} as={Link} to={to}>
          {children}
        </Container>
      );
    }

    // div
    return <Container brand={brand}>{children}</Container>;
  };

  return container(
    <>
      {!!title && (
        <Title>
          {!!icon && <MaterialIcon icon={icon} />}
          <span>{title}</span>
        </Title>
      )}
      <p>{children}</p>
    </>
  );
}

export default Card;
