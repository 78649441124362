import React from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';

import { QueryConsumer } from '../../context';

const GlobalStyles = createGlobalStyle`
  .code-block-title {
    position: relative;
    top: 30px;
    background-color: #efefef;
    padding: 5px 10px;
  }
  .gatsby-highlight-code-line {
    background-color: #feb;
    display: block;
    margin-right: -1em;
    margin-left: -1em;
    padding-right: 1em;
    padding-left: 0.75em;
    border-left: 0.25em solid ${props => props.theme.primary};
  }
`;

const Markdown = styled.div`
  code,
  pre {
    font-family: 'Roboto Mono', Monaco, courier, monospace;
    background-color: #f8f8f8;
    -webkit-font-smoothing: initial;
  }

  code {
    color: ${props => props.theme.primary};
    border-radius: 2px;
    white-space: nowrap;
    font-size: 1.4rem;
  }

  // Code block
  pre {
    padding: 1.2em 1.4em;
    margin-top: 3rem;
    overflow-x: auto;


    > code {
      color: inherit; // Removes color from code block
      white-space: pre;
    }
  }

  h1,
  h2,
  h3,
  h4,
  strong {
    position: relative;
    font-weight: 600;
    color: #2c3e50;
  }

  h1 {
    margin-top: 0;
    font-size: 4rem;
  }

  h2 {
    font-size: 3rem;
    border-bottom: 1px solid rgba(44, 62, 80, 0.3);
    height: 60px;
    margin: 6rem 0 0;
  }

  h3 {
    font-size: 2rem;
    margin-top: 5.5rem;
  }

  p,
  ul,
  ol {
    font-size: 1.7rem;
    line-height: 1.6em;
    margin: 1.2em 0 -1.2em;
    padding-bottom: 1.2em;
    letter-spacing: 0.01rem;
  }

  blockquote {
    margin: 20px 0;
    padding: 10px 0;
    border-left: 5px solid ${props => props.theme.primary};
    background-color: #f8f8f8;
    min-height: 45px;
    display: flex;
    align-items: center;

    p {
      margin: 0;
      padding: 0 15px;
    }

    a {
      border-bottom: 1px dashed rgba(0, 0, 0, 0.5);
    }
  }

  table {
  display: none;
    width: 100%;
    margin: 30px 0;
    text-align: left;
    font-size: 1.6rem;
    border-spacing: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);

    thead th {
      padding: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 1.8rem;
    }

    tbody {
      tr {
        td {
          padding: 10px;
        }

        td:first-child {
          white-space: nowrap;
        }

        &:nth-child(even) {
          td {
            background: rgba(0, 0, 0, 0.02);
          }
        }
      }
    }
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin: 50px 0;
    padding: 0;
  }

  img {
    max-width: 100%;
  }
`;

export default function () {
  return (
    <>
      <GlobalStyles />
      <Markdown>
        <QueryConsumer>
          {({ content }) => content.html}
        </QueryConsumer>
      </Markdown>
    </>
  );
}
