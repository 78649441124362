import React from 'react';

import Sidebar from '../../components/Sidebar';
import Markdown from '../../components/Markdown';
import ToC from '../../components/ToC';
import Tabs from '../../components/Tabs';
import Versions from '../../components/Versions';
import FAB from '../../components/FAB';
import Breadcrumbs from '../../components/Breadcrumbs';

import { Reference, VersionsWrapper, SidebarWrapper, MarkdownWrapper, ToCWrapper } from './styled';

export default class extends React.PureComponent {
  renderActions() {
    return [
      <VersionsWrapper key="versions">
        <Versions />
      </VersionsWrapper>,
    ];
  }

  render() {
    return (
      <>
        <Tabs
          sidebar
          actions={this.renderActions()}
        />
        <Reference>
          <SidebarWrapper>
            <Sidebar.Sticky />
          </SidebarWrapper>
          <MarkdownWrapper>
            <Breadcrumbs />
            <Markdown />
          </MarkdownWrapper>
          <ToCWrapper>
            <ToC.Sticky />
          </ToCWrapper>
        </Reference>
        <FAB>
          <h4>Change version:</h4>
          <Versions />
          <ToC />
        </FAB>
      </>
    );
  }
}
