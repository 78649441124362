import React from 'react';
import styled from 'styled-components';
import stc from 'string-to-color';
import contrast from 'font-color-contrast';

const OVERRIDES = {
  android: '#77C159',
  ios: '#007aff',
};

const StyledSpan = styled.span`
  text-transform: uppercase;
  font-size: 1.4rem;
  border-radius: 16px;
  font-weight: 700;
  padding: 7px 15px;
  margin-right: 5px;
  background-color: ${props => props.background};
  color: ${props => props.color};
`;

export default function ({ color, children }) {
  const str = children.join(' ');
  let background = '';

  if (color) background = color;
  else if (OVERRIDES[str]) background = OVERRIDES[str];
  else background = stc(str);

  return (
    <StyledSpan
      background={background}
      color={contrast(background)}
    />
  );
}
