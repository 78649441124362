import styled, { css, keyframes } from "styled-components";

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.8);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
`;

export const Discord = styled.div`
  margin-top: 10px;
  
  ::before {
    content: '';
    display: inline-block;
    margin-right: 10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    
    ${props => props.status === 'loading' && css`
      background-color: #FFA726;
    `}

    ${props => props.status === 'error' && css`
      background-color: #f44336;
    `}

    ${props => props.status === 'success' && css`
      background-color: #66BB6A;
      box-shadow: 0 0 0 rgba(76, 175, 80, 0.8);
      animation: 2s ${pulse} infinite;
    `}
  }
`;
