import React from 'react';
import { Sticky } from 'react-sticky';

import { withMaxWindowHeight } from '../../../../utils';

import Scrollbar from '../../components/Scrollbar';
import { QueryConsumer } from '../../context';
import Link from '../../rehype/Link';

import { List } from './styled';

function ToC() {
  return (
    <QueryConsumer>
      {({ content }) => {
        return (
          <List
            currentClassName={'active'}
            items={content.tableOfContents.map(({ id }) => id)}
          >
            {content.tableOfContents.map(({ depth, value, id }) => (
              <List.Item
                key={`${depth}_${value}`}
                heading={depth === 2}
                method={depth === 3}
              >
                <Link to={`#${id}`}>{value}</Link>
              </List.Item>
            ))}
          </List>
        )
      }}
    </QueryConsumer>
  );
}

//
// function ToC({ maxHeight }) {
//   return (
//     <QueryConsumer>
//       {({ content }) => {
//         // Don't show if there are no methods/h3s
//         if (!content.tableOfContents.filter($ => $.depth === 3).length) {
//           return null;
//         }
//
//         return (
//           <Sticky>
//             {({ style, isSticky }) => {
//               return (
//                 <div
//                   style={{
//                     maxHeight: maxHeight - (isSticky ? 125 : 180),
//                     overflowY: 'auto',
//                     ...style,
//                     top: isSticky ? 122 : 0,
//                   }}
//                 >
//                   <List
//                     currentClassName={'active'}
//                     items={content.tableOfContents.map(({ id }) => id)}
//                   >
//                     {content.tableOfContents.map(({ depth, value, id }) => (
//                       <li
//                         key={`${depth}_${value}`}
//                         className={cx({
//                           [styles.heading]: depth === 2,
//                           [styles.method]: depth === 3,
//                         })}
//                       >
//                         <Link to={`#${id}`}>{value}</Link>
//                       </li>
//                     ))}
//                   </List>
//                 </div>
//               );
//             }}
//           </Sticky>
//         );
//       }}
//     </QueryConsumer>
//   );
// }

ToC.Sticky = withMaxWindowHeight(function ({ maxHeight }) {
  return (
    <Sticky>
      {({ style, isSticky }) => (
        <Scrollbar
          style={{
            maxHeight: maxHeight - (isSticky ? 125 : 180),
            ...style,
            top: isSticky ? 122 : 0,
          }}
        >
          <ToC />
        </Scrollbar>
      )}
    </Sticky>
  );
}, 380);

export default ToC;
