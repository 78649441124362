import React from 'react';
import Chevron from 'react-feather/dist/icons/chevron-down';
import MaterialIcon from 'material-icons-react';

import Link from '../../rehype/Link'
import { Section } from './styled';

const menu = {
  title: 'Quick Start',
  children: [
    {
      text: 'Getting Started',
      to: '/'
    },
    {
      text: 'Installation',
      to: '/'
    },
    {
      text: 'Android Install',
      to: '/',
      children: [
        {
          text: 'Nested Item',
          to: '/',
        },
        {
          text: 'Nested Item',
          to: '/',
        }
      ]
    },
    {
      text: 'iOS Install',
      to: '/'
    },
    {
      text: 'Using the CLI',
      to: '/'
    }]
};

export default class extends React.PureComponent {

  state = {
    open: true,
    items: {},
  };

  _toggle = () => {
    return this.setState(({ open }) => ({
      open: !open,
    }));
  };

  _toggleItem = (index) => {
    return this.setState(prevState => {
      return {
        items: {
          ...prevState.items,
          [index]: !prevState.items[index],
        }
      };
    });
  };

  /**
   * Renders a nested item, or sub-nested items
   */
  renderItem = ({ to, text, icon, children }, index) => {
    const open = !!this.state.items[index];

    return (
      <>
        <Section.Item open={open}>
          <div>
            {!!icon && <MaterialIcon icon={icon} />}
            <Link to={to}>{text}</Link>
          </div>
          {!!children && !!children.length && (
            <div onClick={() => this._toggleItem(index)}>
              <Chevron />
            </div>
          )}
        </Section.Item>
        {!!children && !!children.length && (
          <Section.Group open={open}>
            {children.map(({ to, text }) => (
              <Section.Item nested>
                <Link to={to}>{text}</Link>
              </Section.Item>
            ))}
          </Section.Group>
        )}
      </>
    );
  };

  render() {
    return (
      <>
        <Section.Heading
          onClick={this._toggle}
          open={this.state.open}
        >
          <div>{menu.title}</div>
          <div><Chevron /></div>
        </Section.Heading>
        <Section.Group open={this.state.open}>
          {menu.children.map(this.renderItem)}
        </Section.Group>
      </>
    );
  }
}
