import styled, { css } from 'styled-components';
import UnstyledLink from '../../rehype/Link';

const tabHeight = 58;

export const Tabs = styled.section`
  color: #ffffff;
  box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.2);
`;

export const Row = styled.div`
  position: relative;
  z-index: 9;
  height: ${tabHeight}px;
  background: linear-gradient(90deg, ${props => props.theme.primary} 0%, ${props => props.theme.secondary} 100%);
  
  h3 {
    font-size: 2.4rem;
    font-weight: 600;
  }
`;

export const Menu = styled.div`
  width: 40px;
  height: ${tabHeight}px;
  display: none;
  align-items: center;
  
  ${props => props.theme.noSidebar`
    display: flex;
  `}
`;

export const Links = styled.div`
  display: flex;
  position: relative;
`;

Links.Scrollable = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  overflow-x: auto;
  
  // If the page has a sidebar, but the screen has removed it
  ${props => props.sidebar && props.theme.noSidebar`
    left: 40px;  
  `}

  a {
    display: inline-flex;
    height: ${tabHeight - 3}px; 
    align-items: center;
    padding: 0 20px;
    font-size: 1.6rem;
    color: rgba(255, 255, 255, 0.8);

    &:hover {
      color: inherit;
      border-bottom: 3px solid #ffffff;
    }

    &.active {
      color: rgba(255, 255, 255, 1);
      border-bottom: 3px solid #ffffff;
    }
  }
`;

export const Link = styled(UnstyledLink)`
  ${props => props.active && css`
    color: rgba(255, 255, 255, 1);
    border-bottom: 3px solid #ffffff;
  `}
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;

  ${props => props.theme.contentOnly`
    display: none;
  `}
`;
