import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: block;
  padding: 40px;
  transition: background-color 0.3s ease-in;
  
  &&& {
    h3 {
      margin: 0;
      padding: 0;
    }
    
    :hover {
      color: inherit;
    }
  }
  
  ${props => props.brand === 'default' && css`
    background: #fbfbfb;
    border: 1px solid #f2f2f2;
   
    ${props => props.anchor && css`
      :hover {
        background: ${props => props.theme.darken('#fbfbfb', 0.02)};
      }
    `}
  `}
  
  ${props => props.brand === 'discord' && css`
    background: #7289DA;
    color: #ffffff;
    border: 1px solid ${props => props.theme.darken('#7289DA', 0.1)};
    
    &&& {
      h3 {
        color: #ffffff;
  
        ::before {
          content: url(https://static.invertase.io/assets/social/discord-logo-white.svg);
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
      }
    }
 
    ${props => props.anchor && css`
      :hover {
        background: ${props => props.theme.darken('#7289DA', 0.02)};
        color: #ffffff;
      }
    `}
  `}
  
  ${props => props.brand === 'android' && css`
    background: #77C159;
    color: #ffffff;
    border: 1px solid ${props => props.theme.darken('#77C159', 0.1)};
    
    h3 {
      color: #ffffff;

      &::before {
        content: '';
        width: 40px;
        height: 40px;
        margin-right: 10px;
        background-image: url(https://static.invertase.io/assets/social/android-logo-white.svg);
        background-size: 40px 40px;
        background-repeat: no-repeat;
      }
    }
    
    ${props => props.anchor && css`
      :hover {
        background: ${props => props.theme.darken('#77C159', 0.05)};
        color: #ffffff;
      }
    `}
  `}
  
  ${props => props.brand === 'ios' && css`
    background: #333333;
    color: #ffffff;
    border: 1px solid ${props => props.theme.darken('#333333', 0.2)};
    
    h3 {
      color: #ffffff;

      &::before {
        content: '';
        width: 40px;
        height: 40px;
        margin-right: 10px;
        background-image: url(https://static.invertase.io/assets/social/apple-logo-white.svg);
        background-size: 40px 40px;
        background-repeat: no-repeat;
      }
    }
    
    ${props => props.anchor && css`
      :hover {
        background: ${props => props.theme.darken('#333333', 0.05)};
        color: #ffffff;
      }
    `}
  `}
`;

export const Title = styled.h3`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 2.4rem;
  
  i {
    margin-right: 8px;
  }
`;
