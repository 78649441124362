import styled, { css } from 'styled-components';

export const FAB = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background: ${props => props.theme.primary};
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s;

  &:hover {
    cursor: pointer;
    background: ${props => props.theme.darken(props.theme.primary, 0.05)};
  }

  ${props => props.theme.contentOnly`
    display: flex;
  `}
`;

export const Content = styled.div`
  display: none;
  opacity: 0;
  background-color: #ffffff;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  padding: 20px;
  width: auto;

  > div {
    opacity: 0;
    transition: transform 0.2s ease-in, opacity 0.35s ease-in-out;
    transform: translateY(100px);
  }
  
  ${props => props.open && css`
    opacity: 1;
    pointer-events: auto;

    > div {
      opacity: 1;
      transform: translateY(0);
    }
  `}
  
  ${props => props.theme.contentOnly`
    display: block;
  `}
`;
