import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(0px, 1fr) minmax(0, 1200px) minmax(0px, 1fr);
  padding: 0 20px;
`;

export default function ({ children }) {
  return (
    <Container>
      <div />
      <div>{children}</div>
      <div />
    </Container>
  );
}
