import React from 'react';
import styled from 'styled-components';

import Link from './Link';

export default styled(Link)`
  padding: 8px 15px;
  font-size: 1.4rem;
  border-radius: 3px;
  border: 1px solid ${props => props.theme.primary};
  color: ${props => props.theme.primary};
  transition: all 250ms ease-in;

  &:hover {
    background-color: #fefefe;
    cursor: pointer;
    color: ${props => props.theme.darken(props.theme.primary, 0.15)};
    border-color: ${props => props.theme.darken(props.theme.primary, 0.15)};
  }
`;
