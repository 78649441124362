import React from 'react';
import axios from 'axios';
import dayjs from 'dayjs';

import Link from '../../rehype/Link';
import { Row, Question, Answers, Meta } from './styled';

class StackOverflow extends React.PureComponent {
  state = {
    loading: true,
    error: false,
    questions: [],
  };

  get endpoint() {
    return `https://api.stackexchange.com/2.2/questions?page=1&pagesize=${this.props.limit}&order=desc&sort=activity&tagged=${this.props.tag}&site=stackoverflow&key=P1m5c3Pyz6YLow6zwGTtWg((`;
  }

  componentDidMount() {
    this._fetch();
  }

  _fetch = async () => {
    try {
      const { data } = await axios.get(this.endpoint);

      if (data.quota_remaining === 0) {
        return this.setState({
          loading: false,
          error: true,
        });
      }

      this.setState({
        loading: false,
        questions: data.items,
      });
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
        error: true,
      });
    }
  };

  render() {
    const { loading, error, questions } = this.state;

    const container = status => {
      return children => (
        <div>
          <h2>Stack Overflow</h2>
          <div>{children}</div>
        </div>
      );
    };

    if (loading) {
      return container('loading')(<div>loading...</div>);
    }

    if (error) {
      return container('error')(<div>error...</div>);
    }

    return container()(
      <div>
        {questions.map((q, i) => {
          return (
            <Row key={i}>
              <Question>
                <Answers answered={!!q.answered}>
                  <Answers.Count>{q.answer_count}</Answers.Count>
                  <div>answers</div>
                </Answers>
                <h4>
                  <Link to={q.link} dangerouslySetInnerHTML={{ __html: q.title }} />
                </h4>
              </Question>
              <Meta>
                <div>{dayjs(q.creation_date).format('DD/MM/YYYY HH:mm')}</div>
                <Meta.Owner>
                  <Link to={q.owner.link}>
                    <img src={q.owner.profile_image} alt={q.owner.display_name} />
                  </Link>
                  <Link to={q.owner.link}>
                    <span>{q.owner.display_name}</span>
                  </Link>
                </Meta.Owner>
              </Meta>
            </Row>
          );
        })}
      </div>
    );
  }
}

export default StackOverflow;
