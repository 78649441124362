import { css } from "styled-components";

export const sidebar = 300;
export const toc = 250;
export const content = 850;

export const noSidebar = (...args) => css`
  @media (max-width: ${sidebar + (content + 80) + toc}px) {
    ${css(...args)}
  }
`;

export const contentOnly = (...args) => css`
  @media (max-width: ${content + toc}px) {
    ${css(...args)}
  }
`;
