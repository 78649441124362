import { Children } from 'react';
import styled, { css } from 'styled-components';

const height = 40;
const width = 265;

export const Container = styled.div`
  transform: translateX(-${width + 10}px);
  transition: transform 0.3s ease-in;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 12;
  width: ${width}px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.4);
  background: #ffffff;
  pointer-events: none;
  
  ${props => props.open && css`
    pointer-events: auto;
    transform: translateX(0);
  `}
`;

export const Background = styled.div`
  background-color: ${props => props.theme.rgba(props.theme.primary, 0.04)};
  height: 100%;
`;

export const Menu = styled.div`
  margin: 0 0 0 30px;
  font-size: 1.6rem;
`;

export const Section = {};

Section.Heading = styled.div.attrs({
  role: 'button',
})`
  display: grid;
  grid-template-columns: 1fr 40px;
  align-items: center;
  margin: 20px 0;
  min-height: 40px;
  text-transform: uppercase;
  font-weight: 600;
  color: ${props => props.theme.primary};
  border-bottom: 1px solid ${props => props.theme.rgba(props.theme.primary, 0.2)};
  
  :hover {
    cursor: pointer;
  }
  
  svg {
    transition: transform 0.2s, opacity 0.2s;
    opacity: 0.3;
  }
  
  ${props => props.open && css`
    svg {
      transform: rotate(180deg);
      opacity: 1;
    }
  `}
`;

Section.Group = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s;
  ${console.log}
  ${props => props.open && css`
    max-height: ${props => {
      let count = 0;

      Children.forEach(props.children, (child) => {
        count += 1;
        count += Children.count(child.props.children);
      });
      
      return (count * height) + height;
    }}px;
  `}
  
  a {
    display: grid;
    grid-template-columns: 1fr 40px;
    height: ${height}px;
    align-items: center;
  }
`;

Section.Item = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.rgba(props.theme.primary, 0.15)};
  
  :hover {
    cursor: pointer;
  }
  
  > div {
    display: flex;
    align-items: center;
    
    i {
      font-size: 1.8rem;
      margin-right: 10px;
    }
  }
  
  ${props => props.nested && css`
    margin-left: 25px;
  `}
  
  svg {
    transition: transform 0.2s, opacity 0.2s;
    opacity: 0.3;
  }
  
  ${props => props.open && css`
    svg {
      transform: rotate(180deg);
      opacity: 1;
    }
  `}
`;
