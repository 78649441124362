import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const withGuides = ComposedComponent => {
  return class extends React.Component {
    /**
     * Return nodes as an array
     * @param allMarkdownRemark
     * @returns {*}
     */
    getList({ allMarkdownRemark }) {
      return allMarkdownRemark.edges.map($ => $.node);
    }

    render() {
      return (
        <StaticQuery
          query={graphql`
            query {
              allMarkdownRemark(
                filter: {
                  fields: { group: { eq: "releases" }, type: { eq: "child" } }
                }
              ) {
                edges {
                  node {
                    excerpt(pruneLength: 800)
                    fields {
                      slug
                      title
                      group
                      shortTitle
                      frontmatter
                    }
                  }
                }
              }
            }
          `}
          render={data => (
            <ComposedComponent {...this.props} data={this.getList(data)} />
          )}
        />
      );
    }
  };
};

export default withGuides;
