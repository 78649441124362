import React from 'react';
import MaterialIcon from 'material-icons-react';

// https://www.npmjs.com/package/material-icons-react

export default function Icon({ type, ...other }) {
  debugger;
  const props = { ...other };

  if (type) props[type] = true;

  return <MaterialIcon {...props} />;
}
